import SimpleBarStyled from '@components/containers/SimpleBarStyled'
import Iconify from '@components/data-display/Iconify'
import DrawerHead from '@components/drawer/DrawerHead'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import useVineyardStore from '@stores/vineyard'
import { BlockDto } from '@typings/dtos/block'
import { formatISOWithCustomHours } from '@utils/utility-fns'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

type ScanTimelineDrawerProps = {
  open: boolean
  onClose: VoidFunction
}

const ScanTimelineDrawer = ({ open, onClose }: ScanTimelineDrawerProps) => {
  const [scans, setScans] = useState<{ scanDate: string; vineyards: { name: string; blocks: BlockDto[] }[] }[]>([])

  const { t } = useTranslate()
  const [blocksGroupByScanDate, blocksAsMap, vineyards, setSelectedBlocksLocal, setStartDate, setEndDate] = useVineyardStore(
    useShallow((s) => [
      s.blocksGroupByScanDate,
      s.blocksAsMap,
      s.vineyards,
      s.setSelectedBlocksLocal,
      s.setStartDateLocal,
      s.setEndDateLocal,
    ])
  )

  /* Find all the blocks with these blockIds and add it to selectedBlocks */
  const handleAddBlocksToFilters = (blocksIds: number[], date: string) => {
    const blocksToAdd = blocksIds.map((blockId) => blocksAsMap.get(blockId) as BlockDto)
    setSelectedBlocksLocal(blocksToAdd)
    setStartDate(formatISOWithCustomHours(new Date(date)))
    setEndDate(formatISOWithCustomHours(new Date(date), { h: 23, m: 59, s: 59, ms: 999 }))
    onClose()
  }

  const sortScansAndGroupByVineyard = () => {
    let scansGroupByVineyard: { scanDate: string; vineyards: { name: string; blocks: BlockDto[] }[] }[] = []

    for (const group of blocksGroupByScanDate) {
      const vineyardsGroup = group.blocks.reduce(
        (acc, block) => {
          const vineyard = vineyards.find((v) => v.id === block.vineyardId)
          if (vineyard) {
            const existingVineyard = acc.find((v) => v.name === vineyard.name)
            if (existingVineyard) {
              existingVineyard.blocks.push(block)
            } else {
              acc.push({ name: vineyard.name, blocks: [block] })
            }
          }
          return acc
        },
        [] as { name: string; blocks: BlockDto[] }[]
      )

      scansGroupByVineyard.push({ scanDate: group.scanDate, vineyards: vineyardsGroup })
    }

    scansGroupByVineyard.sort((a, b) => new Date(b.scanDate).getTime() - new Date(a.scanDate).getTime())
    setScans(scansGroupByVineyard)
  }

  useEffect(() => {
    sortScansAndGroupByVineyard()
  }, [blocksGroupByScanDate])

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{ sx: { width: 440, overflow: 'hidden' } }}
      slotProps={{ backdrop: { invisible: true } }}
      keepMounted
    >
      <DrawerHead label={t('scan_timeline')} onClose={onClose} icon="mdi:timeline-check" width={28} />
      <SimpleBarStyled
        sx={{
          height: 1,
          px: 3,
          pt: 2,
          pb: 10,
          '& .simplebar-content': { display: 'flex', flexDirection: 'column' },
        }}
      >
        {scans.map((group, index) => (
          <div key={index} style={{ display: 'flex', gap: 16 }}>
            <div
              style={{
                width: 2,
                minHeight: 60,
                position: 'relative',
                display: 'flex',
                background: 'linear-gradient(#64C043, rgba(100, 192, 67, 0)',
              }}
            >
              <Box sx={{ position: 'absolute', top: 0, left: -11, bgcolor: 'white', height: 20 }}>
                <Iconify icon="ri:time-fill" color="primary.main" width={24} />
              </Box>
            </div>
            <Box width="100%" display="grid">
              <Box display="flex" alignItems="center" mt={-0.3}>
                <Typography fontSize={15} fontWeight={500} lineHeight={1.6} noWrap color="primary.dark">
                  {group.scanDate && format(new Date(group.scanDate), 'dd MMM yyyy')}
                </Typography>
                <IconButton
                  title="Add to Filters"
                  size="small"
                  sx={{ ml: 'auto', backgroundColor: '#6B7280', ':hover': { backgroundColor: '#4B5563' } }}
                  onClick={() =>
                    handleAddBlocksToFilters(
                      group.vineyards
                        .map((v) => v.blocks)
                        .flat()
                        .map((b) => b.id),
                      group.scanDate
                    )
                  }
                >
                  <Iconify icon="mingcute:filter-3-fill" color="white" />
                </IconButton>
              </Box>
              <div style={{ display: 'grid', paddingBottom: 32 }}>
                {group.vineyards.map((v, i) => (
                  <div
                    key={i}
                    style={{
                      display: 'grid',
                      borderBottom: i === group.vineyards.length - 1 ? 'none' : '1px solid #E7E5E4',
                      padding: '4px 0 10px 0',
                      gap: 2,
                    }}
                  >
                    <span style={{ color: 'rgb(97, 97, 97)', fontWeight: 500, fontSize: 14 }}>{v.name}</span>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                      {v.blocks.map((block, index) => (
                        <div
                          key={index}
                          style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                            color: '#6B7280',
                            borderRadius: 4,
                            padding: '0 6px',
                            fontSize: 14,
                            fontWeight: 500,
                          }}
                        >
                          {block.name}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </Box>
          </div>
        ))}
      </SimpleBarStyled>
    </Drawer>
  )
}

export default ScanTimelineDrawer
