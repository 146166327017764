import { BlockApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { BlockDto } from '@typings/dtos/block'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useGetVineyardsApi } from './useGetVineyardsApi'
import { useHandleAxiosError } from './useHandleAxiosError'

export function useGetBlocksAndScannedApis() {
  const [setBlocks, setBlocksGroupByScanDate, setBlockIdsByPruningStyle] = useVineyardStore(
    useShallow((s) => [s.setBlocks, s.setBlocksGroupByScanDate, s.setBlockIdsByPruningStyle])
  )

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const handleAxiosError = useHandleAxiosError()

  /* GET vineyards */
  const { isLoadingVineyards, isFetchingVineyards } = useGetVineyardsApi()

  /* GET Blocks Scanned */
  const { data: blockScannedData, isFetching: isFetchingScanned } = useQuery({
    queryKey: ['blocksScanned'],
    queryFn: () => BlockApi.getBlocksScanned(),
    initialData: [],
  })

  /* GET Blocks */
  const {
    data: blocksData,
    isLoading: isLoadingBlocks,
    error: blocksError,
    isError: blocksIsError,
    isFetching: isFetchingBlocks,
  } = useQuery({
    queryKey: ['blocks'],
    queryFn: () => BlockApi.getBlocks(),
    initialData: [],
    // select: (data) =>
    //   data.map((block) => {
    //     const scanDate = blockScannedData.find((b) => b.blockId === block.id)?.scanDate || ''
    //     return { ...block, scanDate }
    //   }),
  })

  /* Add scanned dates in blocks data + create/set varieties */
  useEffect(() => {
    if (blocksData && blockScannedData) {
      const blocksWithScannedDates = blocksData.map((block) => {
        const scanDate = blockScannedData.find((b) => b.blockId === block.id)?.scanDate || ''
        return { ...block, scanDate }
      })
      setBlocks(blocksWithScannedDates)

      /* Grouping blocks by scanned date, so it can be used inside block scan timeline */
      const updatedGroupedDataArray: {
        scanDate: string
        blocks: BlockDto[]
      }[] = []

      for (const scanData of blockScannedData) {
        const existingGroup = updatedGroupedDataArray.find((group) => group.scanDate === scanData.scanDate)
        if (existingGroup) {
          const existingBlock = blocksData.find((block) => block.id === scanData.blockId)
          if (existingBlock) {
            existingGroup.blocks.push(existingBlock)
          }
        } else {
          const existingBlock = blocksData.find((block) => block.id === scanData.blockId)
          if (existingBlock) {
            updatedGroupedDataArray.push({ scanDate: scanData.scanDate, blocks: [existingBlock] })
          }
        }
      }


      setBlocksGroupByScanDate(updatedGroupedDataArray)

      /* Set block ids by pruning style */
      setBlockIdsByPruningStyle(blocksData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockScannedData, blocksData])

  /* Show Snackbar while fetching blocks */
  useEffect(() => {
    if (isFetchingBlocks) {
      enqueueSnackbar(`Fetching blocks...`, {
        variant: 'info',
        key: 'fetching-blocks',
        autoHideDuration: null,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    } else {
      closeSnackbar('fetching-blocks')
    }

    if (isFetchingScanned) {
      enqueueSnackbar(`Fetching scan timeline...`, {
        variant: 'info',
        key: 'fetching-scanned-blocks',
        autoHideDuration: null,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    } else {
      closeSnackbar('fetching-scanned-blocks')
    }
  }, [isFetchingScanned, isFetchingBlocks, enqueueSnackbar, closeSnackbar])

  useEffect(() => {
    if (blocksIsError) handleAxiosError(blocksError)
  }, [blocksError, blocksIsError, handleAxiosError, isFetchingScanned])

  const isLoadingBlocksAndVineyards =
    isLoadingBlocks || isLoadingVineyards || isFetchingBlocks || isFetchingVineyards || isFetchingScanned

  return { isLoadingBlocksAndVineyards }
}
