import { CountryCode } from '@typings/common'
import { BlockNamesWithSameValue } from '@typings/component'
import { subMonths } from 'date-fns'
import { formatISOWithCustomHours } from './utility-fns'

export const DEFAULT_START_DATE = formatISOWithCustomHours(subMonths(new Date(), 1))

export const DEFAULT_END_DATE = formatISOWithCustomHours(new Date(), { h: 23, m: 59, s: 59, ms: 999 })

export const DEFAULT_BLOCK_NAMES_WITH_SAME_VAL: BlockNamesWithSameValue = { blockNames: [], value: 0 }

export const countriesInNorthHemisphere: CountryCode[] = ['US', 'FR']
export const countriesInSouthHemisphere: CountryCode[] = ['NZ', 'AU']

export const pinnedColumns = { pinnedColumns: { left: ['scanDate', 'vineyard', 'blockId', 'rowNumber'] } }

export const queryKeys = {
  RowStatsMildewAndFD: 'RowStatsMildewAndFD',
  RowStatsPruningAndLand: 'RowStatsPruningAndLand',
  RowStatsYield: 'RowStatsYield',
}
