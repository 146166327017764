import CustomGridToolbar from '@components/datagrid/CustomGridToolbar'
import { useHandleAxiosError } from '@hooks/useHandleAxiosError'
import Paper from '@mui/material/Paper'

import DatagridNoRowsOverlay from '@components/data-display/DatagridNoRowsOverlay'
import useDataGridLocaleText from '@hooks/useDataGridLocaleText'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { VineRowStatsApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from '@utils/constants'
import { yieldCols } from '@utils/datagrid-cols/yield-cols'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

const YieldReportingPage = () => {
  const [selectedBlocks, startDate, endDate, blocksAsMap, vineyardsAsMap] = useVineyardStore(
    useShallow((s) => [s.selectedBlocks, s.startDate, s.endDate, s.blocksAsMap, s.vineyardsAsMap])
  )

  const handleAxiosError = useHandleAxiosError()
  const { localeText, t } = useDataGridLocaleText()

  const { data, refetch, isFetching, error } = useQuery({
    queryKey: [queryKeys.RowStatsYield],
    initialData: [],
    queryFn: () =>
      VineRowStatsApi.getRowStatsYield({
        blockIds: selectedBlocks.map((block) => block.id),
        dateStart: startDate,
        dateEnd: endDate,
        qualityFilter: true,
      }),
    enabled: false,
  })

  useEffect(() => {
    if (error) handleAxiosError(error)
  }, [error, handleAxiosError])

  useEffect(() => {
    if (!selectedBlocks.length) return
    refetch()
  }, [selectedBlocks, startDate, endDate, refetch])

  return (
    <Paper sx={{ borderRadius: 2, height: 'calc(100vh - 90px)', overflow: 'hidden', mt: 1 }} variant="outlined">
      <DataGridPro
        rows={data}
        columns={yieldCols(blocksAsMap, vineyardsAsMap, t)}
        loading={isFetching}
        getRowId={(row) => row.vineRowId + row.scanDate}
        disableColumnMenu
        hideFooter
        slots={{
          noRowsOverlay: () => <DatagridNoRowsOverlay data={data} />,
          toolbar: () => (
            <CustomGridToolbar fileName={`Yield Report - ${format(new Date(), 'dd MMM yyyy')}`} rowCount={data?.length} />
          ),
        }}
        // slotProps={{ toolbar: { fileName: `Yield Report - ${format(new Date(), 'dd MMM yyyy')}`, rowCount: data?.length } }}
        localeText={localeText}
      />
    </Paper>
  )
}

export default YieldReportingPage
