import { SettingToggleIconItem } from '@typings/component'
import { SVGProps } from 'react'

type ColorGridIconProps = {
  props?: SVGProps<SVGSVGElement>
} & Required<Pick<SettingToggleIconItem, 'colors'>>

const ColorGridIcon = ({ colors, props }: ColorGridIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="100%" viewBox="0 0 243 203" fill="none" {...props}>
      <path fill={colors.posHigh} d="M0 30C0 13.4315 13.4315 0 30 0H120V100H0V30Z" />
      <path fill={colors.posLow} d="M123 0H213C229.569 0 243 13.4315 243 30V100H123V0Z" />
      <path fill={colors.negLow} d="M123 103H243V173C243 189.569 229.569 203 213 203H123V103Z" />
      <path fill={colors.negHigh} d="M0 103H120V203H30C13.4315 203 0 189.569 0 173V103Z" />
    </svg>
  )
}

export default ColorGridIcon
