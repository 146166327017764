import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { t } from 'i18next'
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack'
import { forwardRef, useCallback, useState } from 'react'
import Iconify from './Iconify'

type ApplyFiltersToastProps = CustomContentProps & {
  onClickApplyFilters: () => void
}

const ApplyFiltersToast = forwardRef<HTMLDivElement, ApplyFiltersToastProps>(({ id, onClickApplyFilters }, ref) => {
  const { closeSnackbar } = useSnackbar()
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded)
  }, [])

  const handleDismiss = useCallback(() => {
    closeSnackbar(id)
  }, [id, closeSnackbar])

  return (
    <SnackbarContent ref={ref} style={{ width: 430 }}>
      <Card style={{ backgroundColor: '#fddc6c', width: '100%' }}>
        <CardActions>
          <span style={{ display: 'flex', gap: 7, alignItems: 'center', lineHeight: 1.2 }}>
            {t('click')} <button onClick={onClickApplyFilters}>{t('apply_filters')}</button> {t('button_to_load_data')}
          </span>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton aria-label="expand" size="small" onClick={handleExpandClick} color="inherit">
              <Iconify
                icon="lets-icons:expand-down"
                width={22}
                sx={{
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'all .2s',
                }}
              />
            </IconButton>
            <IconButton aria-label="delete" size="small" onClick={handleDismiss} color="inherit">
              <Iconify icon="material-symbols:close" />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit aria-hidden={false}>
          <Paper sx={{ p: 2 }}>
            <Typography sx={{ color: 'info.dark', fontWeight: 500, fontSize: 14 }}>{t('toast.changes_not_applied')}</Typography>
            <Typography sx={{ fontSize: 14, mt: 1 }}>{t('toast.apply_changes_description')}</Typography>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  )
})

ApplyFiltersToast.displayName = 'ApplyFiltersToast'

export default ApplyFiltersToast
