import { IOption } from '@typings/common'
import { ImageType } from '../evidence'

export enum VineAttributeName {
  BunchCount = 'Powdery Mildew',
  PowderMildewCount = 'Powdery Mildew on Bunch',
  CanopyCoverageScore = 'Canopy Coverage Score',
}

export enum VineAttributeLookup {
  'Mildew on Leaf' = '3',
  'Mildew on Bunch' = '16',
  'Botrytis' = '11',
  'Damages Bunches' = '17',
  'Flavescence Dorée' = '25',
}

export const vineAttributeLookupOptions: IOption<VineAttributeLookup>[] = [
  { label: 'mildew_leaves', value: VineAttributeLookup['Mildew on Leaf'] },
  { label: 'mildew_bunches', value: VineAttributeLookup['Mildew on Bunch'] },
  { label: 'botrytis', value: VineAttributeLookup.Botrytis },
  { label: 'damaged_bunches', value: VineAttributeLookup['Damages Bunches'] },
  { label: 'flavescence_doree', value: VineAttributeLookup['Flavescence Dorée'] },
]

export const lookUpIdToClassName: Record<VineAttributeLookup, string> = {
  '3': 'Powdery Mildew',
  '16': 'Powdery Mildew on Bunch',
  '11': 'Botrytis Bunch Rot',
  '17': 'Damages Bunches',
  '25': 'Flavescence Dorée',
}

export const lookUpIdToImageType: Record<VineAttributeLookup, ImageType> = {
  '3': ImageType.Mildew,
  '16': ImageType.MildewOnBunch,
  '11': ImageType.BotrytisBunchRot,
  '17': ImageType.BotrytisBunchRot,
  '25': ImageType.FlavescenceDoree,
}
