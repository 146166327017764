import { blockHighlightLayer, vinesLayer } from '@/arcgis/layers'
import { rowsLayer } from '@/arcgis/layers/rows.layer'
import { removeGraphicsFromFeatureLayer } from '@/arcgis/utils'
import DatePickerWrapper from '@components/containers/DatePickerWrapper'
import ScrollingInputWrapper from '@components/containers/ScrollingInputWrapper'
import SimpleBarStyled from '@components/containers/SimpleBarStyled'
import Iconify from '@components/data-display/Iconify'
import MultiChipSelect from '@components/form/MultiChipSelect'
import DialogCreateFavourite from '@components/sections/DialogCreateFavourite'
import DialogRemoveFavourite from '@components/sections/DialogRemoveFavourite'
import { useGetBlocksAndScannedApis } from '@hooks/useGetBlocksAndScannedApis'
import { useHandleFilterFavourites } from '@hooks/useHandleFilterFavourites'
import { useHandleToggleScanRecency } from '@hooks/useHandleToggleScanRecency'
import { useIsChangesSaved } from '@hooks/useIsChangesSaved'
import { useTranslate } from '@hooks/useLocales'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Fab from '@mui/material/Fab'
import LinearProgress from '@mui/material/LinearProgress'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import { DatePicker } from '@mui/x-date-pickers-pro'
import LocalizationProvider from '@services/locales/LocalizationProvider'
import useVineyardStore from '@stores/vineyard'
import { useIsFetching, useQueryClient } from '@tanstack/react-query'
import { BlockDto, Variety } from '@typings/dtos/block'
import { VineyardDto } from '@typings/dtos/vineyard'
import { DEFAULT_END_DATE, DEFAULT_START_DATE, queryKeys } from '@utils/constants'
import { formatISOWithCustomHours } from '@utils/utility-fns'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

type SidebarProps = {
  onSidebarToggle: (state: boolean) => void
}

const Sidebar = ({ onSidebarToggle }: SidebarProps) => {
  const [collapsed, setCollapsed] = useState(false)
  const [openStartDate, setOpenStartDate] = useState(false)
  const [openEndDate, setOpenEndDate] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [activeFilter, setActiveFilter] = useState('')
  const [favouriteToDelete, setFavouriteToDelete] = useState('')

  const [
    vineyards,
    vineyardsForFilter,
    selectedVineyardsLocal,
    setSelectedVineyardsLocal,
    setSelectedVineyards,
    varieties,
    varietiesForFilter,
    setVarietiesForFilter,
    setSelectedVarieties,
    selectedVarietiesLocal,
    setSelectedVarietiesLocal,
    blocks,
    blocksForFilter,
    setBlocksForFilter,
    setSelectedBlocks,
    selectedBlocksLocal,
    setSelectedBlocksLocal,
    setStartDate,
    startDateLocal,
    setStartDateLocal,
    setEndDate,
    endDateLocal,
    setEndDateLocal,
  ] = useVineyardStore(
    useShallow((s) => [
      s.vineyards,
      s.vineyardsForFilter,
      s.selectedVineyardsLocal,
      s.setSelectedVineyardsLocal,
      s.setSelectedVineyards,
      s.varieties,
      s.varietiesForFilter,
      s.setVarietiesForFilter,
      s.setSelectedVarieties,
      s.selectedVarietiesLocal,
      s.setSelectedVarietiesLocal,
      s.blocks,
      s.blocksForFilter,
      s.setBlocksForFilter,
      s.setSelectedBlocks,
      s.selectedBlocksLocal,
      s.setSelectedBlocksLocal,
      s.setStartDate,
      s.startDateLocal,
      s.setStartDateLocal,
      s.setEndDate,
      s.endDateLocal,
      s.setEndDateLocal,
    ])
  )

  const { t } = useTranslate()
  const queryClient = useQueryClient()
  const { isChangesSaved, setIsChangesSaved } = useIsChangesSaved()
  const { isLoadingBlocksAndVineyards } = useGetBlocksAndScannedApis()
  const { toggleScanRecency, setToggleScanRecency, handleToggleScanRecency } = useHandleToggleScanRecency()
  const { favourites, handleFavOnDelete, handleFavChipOnClick } = useHandleFilterFavourites(setActiveFilter, setToggleScanRecency)

  const disableFilterButton = selectedBlocksLocal.length === 0 || isLoadingBlocksAndVineyards

  const disableResetAndFavouriteButton =
    (selectedBlocksLocal.length === 0 && selectedVarietiesLocal.length === 0 && selectedVineyardsLocal.length === 0) ||
    isLoadingBlocksAndVineyards

  const handleVineyardChipOnDelete = (vineyard: VineyardDto) => {
    setSelectedVineyardsLocal(selectedVineyardsLocal.filter((v) => v !== vineyard))
    const selectedVineyardsWithoutDeleted = selectedVineyardsLocal.filter((v) => v !== vineyard)

    if (selectedVineyardsWithoutDeleted.length === 0) {
      setBlocksForFilter(blocks)
      setSelectedBlocksLocal([])
      setVarietiesForFilter(varieties)
      setSelectedVarietiesLocal([])
    } else {
      const filteredBlocks =
        blocks.filter((block) => selectedVineyardsWithoutDeleted.some((vineyard) => block.vineyardId === vineyard.id)) || []

      const selectedBlocksWithoutDeletedVineyard = selectedBlocksLocal.filter((block) => block.vineyardId !== vineyard.id)
      const selectedVarietiesWithoutDeletedVineyard = selectedVarietiesLocal.filter((v) =>
        selectedBlocksWithoutDeletedVineyard.some((block) => block.varietyId === v.id)
      )
      const filteredVarieties = varieties.filter((variety) => filteredBlocks.some((block) => block.varietyId === variety.id))
      setVarietiesForFilter(filteredVarieties)
      setBlocksForFilter(filteredBlocks)
      setSelectedBlocksLocal(selectedBlocksWithoutDeletedVineyard)
      setSelectedVarietiesLocal(selectedVarietiesWithoutDeletedVineyard)
    }
    setActiveFilter('')
    setToggleScanRecency('')
  }

  const handleVineyardClickMenuItem = (vineyard: VineyardDto) => {
    if (selectedVineyardsLocal.find((v) => v.id === vineyard.id)) {
      setSelectedVineyardsLocal(selectedVineyardsLocal.filter((v) => v.id !== vineyard.id))
      const filteredBlocks =
        blocks.filter((block) => selectedVineyardsLocal.some((vineyard) => block.vineyardId === vineyard.id)) || []
      const filteredVarieties = varieties.filter((variety) => filteredBlocks.some((block) => block.varietyId === variety.id))
      setBlocksForFilter(filteredBlocks)
      setVarietiesForFilter(filteredVarieties)
    } else {
      setSelectedVineyardsLocal([...selectedVineyardsLocal, vineyard])
      const selectedAndNewVineyards = [...selectedVineyardsLocal, vineyard]
      const filteredBlocks =
        blocks.filter((block) => selectedAndNewVineyards.some((vineyard) => block.vineyardId === vineyard.id)) || []
      const filteredVarieties = varieties.filter((variety) => filteredBlocks.some((block) => block.varietyId === variety.id))
      setVarietiesForFilter(filteredVarieties)
      setBlocksForFilter(filteredBlocks)
    }
    setActiveFilter('')
    setToggleScanRecency('')
  }

  const handleVineyardOnSelectAll = () => {
    if (selectedVineyardsLocal.length === vineyards.length) {
      setSelectedVineyardsLocal([])
      setSelectedBlocksLocal([])
      setSelectedVarietiesLocal([])
    } else {
      setBlocksForFilter(blocks)
      setVarietiesForFilter(varieties)
      setSelectedVineyardsLocal(vineyards)
    }
    setActiveFilter('')
    setToggleScanRecency('')
  }

  const handleVarietyChipOnDelete = (variety: Variety) => {
    setSelectedVarietiesLocal(selectedVarietiesLocal.filter((v) => v !== variety))
    const selectedVarietiesWithoutDeleted = selectedVarietiesLocal.filter((v) => v !== variety)
    const filteredBlocks =
      selectedVineyardsLocal.length === 0
        ? blocks
        : blocks.filter((block) => selectedVineyardsLocal.some((vineyard) => block.vineyardId === vineyard.id))

    if (selectedVarietiesLocal.length === 0 || selectedVarietiesWithoutDeleted.length === 0) {
      setBlocksForFilter(filteredBlocks)
      setSelectedBlocksLocal([])
    } else {
      const filteredBlocks =
        blocks.filter((block) => selectedVineyardsLocal.some((vineyard) => block.vineyardId === vineyard.id)) || []
      const filteredBlocksByVarieties = filteredBlocks.filter((block) =>
        selectedVarietiesWithoutDeleted.some((v) => v.id === block.varietyId)
      )

      const selectedBlockWithThisVariety = selectedBlocksLocal.filter((block) => block.varietyId !== variety.id)
      setBlocksForFilter(filteredBlocksByVarieties)
      setSelectedBlocksLocal(selectedBlockWithThisVariety)
    }

    setActiveFilter('')
    setToggleScanRecency('')
  }

  const handleVarietyClickMenuItem = (variety: Variety) => {
    if (selectedVarietiesLocal.includes(variety)) {
      setSelectedVarietiesLocal(selectedVarietiesLocal.filter((v) => v !== variety))
      const selectedVarietiesWithoutDeleted = selectedVarietiesLocal.filter((v) => v !== variety)
      if (selectedVarietiesWithoutDeleted.length === 0) {
        const filteredBlocks =
          selectedVineyardsLocal.length === 0
            ? blocks
            : blocks.filter((block) => selectedVineyardsLocal.some((vineyard) => block.vineyardId === vineyard.id))
        setBlocksForFilter(filteredBlocks)
        return
      }
      const filteredBlocksByVarieties = blocks.filter((block) =>
        selectedVarietiesWithoutDeleted.some((v) => v.id === block.varietyId)
      )
      setBlocksForFilter(filteredBlocksByVarieties)
    } else {
      const existingVarietiesAndNewVariety = [...selectedVarietiesLocal, variety]
      setSelectedVarietiesLocal(existingVarietiesAndNewVariety)
      const filteredBlocksByVineyards = selectedVineyardsLocal.length === 0 ? blocks : blocks.filter((block) =>
        selectedVineyardsLocal.some((vineyard) => block.vineyardId === vineyard.id)
      )
      const filteredBlocksByVarieties = filteredBlocksByVineyards.filter((block) =>
        existingVarietiesAndNewVariety.some((v) => v.id === block.varietyId)
      )

      setBlocksForFilter(filteredBlocksByVarieties)
    }
    setActiveFilter('')
    setToggleScanRecency('')
  }

  const handleVarietySelectAll = () => {
    const filteredBlocks =
      blocks.filter((block) => selectedVineyardsLocal.some((vineyard) => block.vineyardId === vineyard.id)) || []

    if (selectedVarietiesLocal.length === varieties.length) {
      setSelectedVarietiesLocal([])
      if (selectedVineyardsLocal.length === 0) return
    } else {
      setSelectedVarietiesLocal(varietiesForFilter)
      if (selectedVineyardsLocal.length === 0) return

      const filteredBlocksByVarieties = filteredBlocks.filter((block) => varieties.some((v) => v.id === block.varietyId))
      setBlocksForFilter(filteredBlocksByVarieties)
    }

    setActiveFilter('')
    setToggleScanRecency('')
  }

  const handleBlockChipOnDelete = (block: BlockDto) => {
    setSelectedBlocksLocal(selectedBlocksLocal.filter((v) => v !== block))
    setActiveFilter('')
    setToggleScanRecency('')
  }

  const handleBlockClickMenuItem = (block: BlockDto) => {
    if (selectedBlocksLocal.includes(block)) {
      setSelectedBlocksLocal(selectedBlocksLocal.filter((v) => v !== block))
    } else {
      setSelectedBlocksLocal([...selectedBlocksLocal, block])
    }
    setActiveFilter('')
    setToggleScanRecency('')
  }

  const handleBlockSelectAll = () => {
    if (selectedBlocksLocal.length === blocksForFilter.length) {
      setSelectedBlocksLocal([])
    } else {
      setSelectedBlocksLocal(blocksForFilter)
    }
    setActiveFilter('')
    setToggleScanRecency('')
  }

  const resetFilters = () => {
    setSelectedBlocksLocal([])
    setSelectedBlocks([])
    setSelectedVineyardsLocal([])
    setSelectedVineyards([])
    setSelectedVarietiesLocal([])
    setSelectedVarieties([])
    setBlocksForFilter(blocks)
    setVarietiesForFilter(varieties)
    setStartDateLocal(DEFAULT_START_DATE)
    setEndDateLocal(DEFAULT_END_DATE)
    setToggleScanRecency('')
    queryClient.resetQueries({
      predicate: (query) =>
        query.queryKey[0] === queryKeys.RowStatsMildewAndFD ||
        query.queryKey[0] === queryKeys.RowStatsPruningAndLand ||
        query.queryKey[0] === queryKeys.RowStatsYield,
    })
    removeGraphicsFromFeatureLayer([vinesLayer, rowsLayer, blockHighlightLayer])
    setActiveFilter('')
    setIsChangesSaved(true)
  }

  const isFetchingRowStats = useIsFetching({
    predicate: (query) =>
      query.queryKey[0] === 'rowStatsYield' || query.queryKey[0] === 'rowStatsPruning' || query.queryKey[0] === 'rowStatsDisease',
  })

  const applyFilters = () => {
    setSelectedBlocks(selectedBlocksLocal)
    setSelectedVineyards(selectedVineyardsLocal)
    setSelectedVarieties(selectedVarietiesLocal)
    setStartDate(startDateLocal)
    setEndDate(endDateLocal)
    setIsChangesSaved(true)
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isChangesSaved) {
      enqueueSnackbar('toast.apply_filters', {
        key: 'apply-filters-toast',
        variant: 'applyFiltersToast',
        persist: true,
        onClickApplyFilters: applyFilters,
      })
    } else closeSnackbar('apply-filters-toast')
  }, [isChangesSaved, enqueueSnackbar, closeSnackbar])

  return (
    <Box
      component="aside"
      sx={{
        left: 0,
        position: 'fixed',
        height: '100%',
        bgcolor: '#F2F2F7',
        width: collapsed ? 0 : 320,
        transition: 'width 0.3s ease',
        zIndex: 2,
      }}
    >
      <SimpleBarStyled sx={{ display: collapsed ? 'none' : 'inherit', p: 1.8 }}>
        <Typography variant="caption" fontWeight={600} color="primary.dark" textTransform="uppercase">
          {t('filters')}
        </Typography>
        <Box height={2}>
          {isLoadingBlocksAndVineyards ? (
            <LinearProgress sx={{ height: 2 }} />
          ) : (
            <div style={{ paddingBottom: 2 }}>
              <Divider sx={{ bgcolor: 'primary.main' }} />
            </div>
          )}
        </Box>
        <Box mt={2} display="grid" gap={1} pb={6}>
          <ScrollingInputWrapper id="favourites" label={t('favourites')}>
            {favourites.map((filter) => (
              <Chip
                key={filter.name}
                label={filter.name}
                size="small"
                onDelete={() => setFavouriteToDelete(filter.name)}
                disabled={isLoadingBlocksAndVineyards}
                sx={{
                  m: 0.5,
                  fontWeight: 500,
                  cursor: 'pointer',
                  maxWidth: '250px !important',
                  bgcolor: activeFilter === filter.name ? 'primary.dark' : '',
                  color: activeFilter === filter.name ? 'white' : '',
                  '.MuiChip-deleteIcon': { color: activeFilter === filter.name ? 'white' : '' },
                  ':hover': {
                    bgcolor: activeFilter === filter.name ? 'primary.dark' : '',
                    color: activeFilter === filter.name ? 'white' : '',
                  },
                }}
                onClick={() => handleFavChipOnClick(filter.name)}
              />
            ))}
          </ScrollingInputWrapper>
          <MultiChipSelect
            id="select-vineyards"
            searchPlaceholder="search"
            placeholder="select_vineyards"
            label={t('vineyards')}
            options={vineyardsForFilter}
            selectedOptions={selectedVineyardsLocal}
            onDeleteChip={(vineyard) => handleVineyardChipOnDelete(vineyard)}
            onClickMenuItem={(vineyard) => handleVineyardClickMenuItem(vineyard)}
            onSelectAll={handleVineyardOnSelectAll}
            onClickClearAll={() => {
              setSelectedVineyardsLocal([])
              setBlocksForFilter(blocks)
              setVarietiesForFilter(varieties)
              setToggleScanRecency('')
            }}
            loading={isLoadingBlocksAndVineyards}
          />
          <MultiChipSelect
            id="select-varieties"
            searchPlaceholder="search"
            placeholder="select_varieties"
            label={t('varieties')}
            options={varietiesForFilter}
            selectedOptions={selectedVarietiesLocal}
            onDeleteChip={(variety) => handleVarietyChipOnDelete(variety)}
            onClickMenuItem={(variety) => handleVarietyClickMenuItem(variety)}
            onSelectAll={handleVarietySelectAll}
            onClickClearAll={() => {
              setSelectedVarietiesLocal([])
              const blocksWithSelectedVineyard =
                selectedVineyardsLocal.length > 0
                  ? blocks.filter((block) => selectedVineyardsLocal.some((v) => v.id === block.vineyardId))
                  : blocks

              setBlocksForFilter(blocksWithSelectedVineyard)
              setToggleScanRecency('')
            }}
            loading={isLoadingBlocksAndVineyards}
          />
          <MultiChipSelect
            id="select-blocks"
            searchPlaceholder="search"
            placeholder="select_blocks"
            label={t('blocks')}
            options={blocksForFilter}
            selectedOptions={selectedBlocksLocal}
            onDeleteChip={(block) => handleBlockChipOnDelete(block)}
            onClickMenuItem={(block) => handleBlockClickMenuItem(block)}
            onSelectAll={handleBlockSelectAll}
            onClickClearAll={() => {
              setSelectedBlocksLocal([])
              setSelectedBlocks([])
            }}
            loading={isLoadingBlocksAndVineyards}
          />
          <LocalizationProvider>
            <Box display="flex" gap={1}>
              <DatePickerWrapper
                label={t('start_date')}
                dateSelected={startDateLocal}
                isOpenDatePicker={openStartDate}
                onClick={() => setOpenStartDate((prev) => !prev)}
              >
                <DatePicker
                  disableHighlightToday
                  slotProps={{ textField: { sx: { height: 0, overflow: 'hidden' } } }}
                  minDate={new Date('2022-01-01')}
                  maxDate={new Date(Date.now() + 86_400_000)} // tomorrow
                  open={openStartDate}
                  value={new Date(startDateLocal)}
                  onChange={(val) => {
                    if (val) {
                      setStartDateLocal(formatISOWithCustomHours(val))
                      setToggleScanRecency('')
                    }
                  }}
                  onClose={() => setOpenStartDate(false)}
                  onOpen={() => setOpenStartDate(true)}
                />
              </DatePickerWrapper>
              <DatePickerWrapper
                label={t('end_date')}
                dateSelected={endDateLocal}
                isOpenDatePicker={openEndDate}
                onClick={() => setOpenEndDate((prev) => !prev)}
              >
                <DatePicker
                  disableHighlightToday
                  slotProps={{ textField: { sx: { height: 0, overflow: 'hidden' } } }}
                  minDate={new Date('2022-01-01')}
                  maxDate={new Date(Date.now() + 86_400_000)} // tomorrow
                  open={openEndDate}
                  value={new Date(endDateLocal)}
                  onChange={(val) => {
                    if (val) {
                      setEndDateLocal(formatISOWithCustomHours(val, { h: 23, m: 59, s: 59, ms: 999 }))
                      setToggleScanRecency('')
                    }
                  }}
                  onClose={() => setOpenEndDate(false)}
                  onOpen={() => setOpenEndDate(true)}
                />
              </DatePickerWrapper>{' '}
            </Box>
          </LocalizationProvider>
          <ToggleButtonGroup
            fullWidth
            exclusive
            aria-label="Scan Recency"
            disabled={blocks.length === 0}
            color="info"
            sx={{ mb: 3, height: 34 }}
            value={toggleScanRecency}
            onChange={(_e, val) => handleToggleScanRecency(val)}
          >
            <ToggleButton value="15" disableRipple>
              &le; 15 {t('days')}
            </ToggleButton>
            <ToggleButton value="30" disableRipple>
              &le; 30 {t('days')}
            </ToggleButton>
            <ToggleButton value="30+" disableRipple>
              &gt; 30 {t('days')}
            </ToggleButton>
          </ToggleButtonGroup>

          <LoadingButton
            variant="contained"
            startIcon={<Iconify icon="mingcute:filter-3-fill" />}
            disabled={disableFilterButton}
            loading={isFetchingRowStats > 0}
            onClick={applyFilters}
            sx={{ position: 'relative' }}
          >
            <span>{t('apply_filters')}</span>
            <Typography
              variant="caption"
              fontWeight={600}
              color="error"
              alignItems="center"
              display={isChangesSaved ? 'none' : 'flex'}
              position="absolute"
              top={-20}
              left={0}
              width={200}
            >
              <Iconify icon="material-symbols:info" width={18} sx={{ mr: 0.4 }} />
              {t('changes_not_applied_yet')}
            </Typography>
          </LoadingButton>
          <Button
            variant="outlined"
            sx={{ color: 'primary.dark' }}
            onClick={() => setOpenDialog(true)}
            disabled={disableResetAndFavouriteButton}
          >
            {t('add_to_favourites')}
          </Button>
          <Button variant="outlined" sx={{ color: 'primary.dark' }} onClick={resetFilters}>
            {t('reset_filters')}
          </Button>
        </Box>
      </SimpleBarStyled>
      <Box position="absolute" top={20} right={collapsed ? -44 : 10}>
        <Fab
          aria-label="toggle sidebar"
          size="small"
          sx={{
            bgcolor: 'white',
            height: 20,
            width: 36,
            border: '1px solid',
            borderColor: 'primary.dark',
            boxShadow: 'none',
            transition: 'opacity 0.3s ease',
            backgroundColor: collapsed ? 'primary.dark' : 'white',
            '&:hover': { backgroundColor: collapsed ? 'primary.main' : 'grey.100' },
          }}
          onClick={() => {
            onSidebarToggle(!collapsed)
            setCollapsed(!collapsed)
          }}
          disableRipple
          disableTouchRipple
          disableFocusRipple
        >
          {collapsed ? (
            <Iconify icon="mingcute:filter-3-fill" sx={{ color: 'white' }} />
          ) : (
            <Iconify icon="material-symbols:chevron-left" sx={{ color: 'primary.dark' }} />
          )}
        </Fab>
      </Box>
      <DialogCreateFavourite open={openDialog} onClose={() => setOpenDialog(false)} />
      <DialogRemoveFavourite
        open={favouriteToDelete !== ''}
        onClose={() => setFavouriteToDelete('')}
        onConfirm={() => {
          handleFavOnDelete(favouriteToDelete)
          setFavouriteToDelete('')
        }}
        favouriteName={favouriteToDelete}
      />
    </Box>
  )
}

export default Sidebar
