import { GridColDef, GridValidRowModel, GridValueFormatter } from '@mui/x-data-grid-pro'
import { ColorRange, CountryCode } from '@typings/common'
import { BlockDto } from '@typings/dtos/block'
import { PruningRowStatsAggregated, RowStatsPruningDto } from '@typings/dtos/vine-row-stats'
import { formatISO } from 'date-fns'
import { TFunction } from 'i18next'
import { countriesInNorthHemisphere } from './constants'
import { seasonsByHemisphere } from './options'

export function localStorageGetItem(key: string, defaultValue = '') {
  return localStorage.getItem(key) || defaultValue
}

export function mapEnumToOptions<T extends Record<string, unknown>>(enumObj: T): Array<{ label: string; value: T[keyof T] }> {
  return Object.keys(enumObj).map((key) => ({
    label: key,
    value: enumObj[key as keyof T],
  }))
}

export function getEnumKeyByValue<T extends Record<string, string | number>>(enumObj: T, value: T[keyof T]): string {
  const entries = Object.entries(enumObj)
  const foundEntry = entries.find(([, enumValue]) => enumValue === value)
  return foundEntry ? foundEntry[0] : 'undefined'
}

export async function copyToClipboard(value: unknown) {
  const stringValue = String(value)

  try {
    await navigator.clipboard.writeText(stringValue)
  } catch (error) {
    console.error('failed to copy to clipboard', error)
  }
}

export function openGoogleMapsLink(longitude: number, latitude: number) {
  const url = `https://www.google.com/maps/search/?api=1&query=${longitude},${latitude}`
  return window.open(url, '_blank', 'noopener,noreferrer')
}

export function formatISOWithCustomHours(date: Date, { h = 0, m = 0, s = 0, ms = 0 } = {}) {
  const newDate = new Date(date)
  newDate.setHours(h, m, s, ms)
  return formatISO(newDate)
}

export function getBlocksFromScanDates(blocks: BlockDto[], startDate: Date, endDate: Date) {
  return blocks.filter((block) => {
    const blockDate = new Date(block.scanDate)
    return blockDate >= startDate && blockDate <= endDate
  })
}

function removeRepeatedVineRowIds(data: RowStatsPruningDto[]): RowStatsPruningDto[] {
  const uniqueVineRowIds = {} as Record<number, RowStatsPruningDto>
  const newData: RowStatsPruningDto[] = []

  for (const item of data) {
    if (!(item.vineRowId in uniqueVineRowIds)) {
      uniqueVineRowIds[item.vineRowId] = item
    } else {
      const existingItem = uniqueVineRowIds[item.vineRowId]
      const newItemDate = new Date(item.scanDate)
      const existingItemDate = existingItem ? new Date(existingItem.scanDate) : null
      if (existingItem && newItemDate > existingItemDate!) {
        uniqueVineRowIds[item.vineRowId] = item
      }
    }
  }

  for (const key in uniqueVineRowIds) {
    newData.push(uniqueVineRowIds[key] as RowStatsPruningDto)
  }

  return newData
}

export function calculateBlockPotential(block: PruningRowStatsAggregated, isRowStatsPruningDto: boolean = false) {
  const caneCounts: { [key: number]: number } = {
    1: block['1 Cane'] || 0,
    2: block['2 Cane'] || 0,
    3: block['3 Cane'] || 0,
    4: block['4 Cane'] || 0,
  }

  // Identify the highest cane type with the highest count
  const highestCaneType = Object.keys(caneCounts).reduce((a, b) => (parseInt(a) > parseInt(b) ? a : b))

  let numerator = 0
  let denominator = 0

  for (let caneType = 1; caneType <= parseInt(highestCaneType); caneType++) {
    numerator += caneType * (caneCounts[caneType] || 0)
    denominator += caneCounts[caneType] || 0
  }

  if (!isRowStatsPruningDto) {
    denominator *= block['Assumed Cane Target'] || 1
  } else {
    //@ts-expect-error - Using same function when block is RowStatsPruningDto and property is 'assumed Cane Target'
    denominator *= block['assumed Cane Target'] || 1
  }

  // Calculate the percentage
  const percentage = (numerator / denominator) * 100
  return percentage.toFixed(1)
}

export function aggregatedPruningRowStatsData(
  data: RowStatsPruningDto[],
  blocksAsMap: Map<number, BlockDto>,
  canePrunedBlockIds: number[],
  spurPrunedBlockIds: number[],
  bowPrunedBlockIds: number[]
): {
  bow: PruningRowStatsAggregated[]
  cane: PruningRowStatsAggregated[]
  spur: PruningRowStatsAggregated[]
} {
  const uniqueRows = removeRepeatedVineRowIds(data)

  const blocksPruningStats = uniqueRows
    .reduce<PruningRowStatsAggregated[]>((result, row) => {
      // Find if the blockId is already in the result array
      const existingBlock = result.find((item) => item['Block Id'] === row.blockId)

      const stats = row.stats
      const normalAndYoungVines = stats['normal Vines'] + stats['young Vines']

      if (existingBlock) {
        // If blockId exists, update the aggregated values
        existingBlock['Total Rows'] += 1
        existingBlock['Normal Vines'] += stats['normal Vines']
        existingBlock['Young Vines'] += stats['young Vines']
        existingBlock['Dead Vines'] += stats['dead Vines']
        // compare the dates and update the start date if the new date is earlier
        existingBlock['Start Date'] =
          new Date(row.scanDate) < new Date(existingBlock['Start Date']) ? row.scanDate : existingBlock['Start Date']
        // compare the dates and update the end date if the new date is later
        existingBlock['End Date'] =
          new Date(row.scanDate) > new Date(existingBlock['End Date']) ? row.scanDate : existingBlock['End Date']
        existingBlock['Missing Vines'] += stats['missing Vines']
        existingBlock['Unknown Vines'] += stats['unknown Vines']
        existingBlock['Bad Quality Vines'] += stats['bad Quality Vines']
        existingBlock['Low Quality Vines'] += stats['bad Quality Vines']
        existingBlock['Productive%'] += stats['productive%']
        existingBlock['1 Cane'] += stats['1 Cane']
        existingBlock['2 Cane'] += stats['2 Cane']
        existingBlock['3 Cane'] += stats['3 Cane']
        existingBlock['4 Cane'] += stats['4 Cane']

        existingBlock['Bow Buds Per Vine Mean'] += normalAndYoungVines * stats['buds Per Vine']
        existingBlock['1 Missing Cordon'] += stats['missing Cordons 1']
        existingBlock['2 Missing Cordons'] += stats['missing Cordons 2']
        existingBlock['Buds Per Vine LQ'] += normalAndYoungVines * stats['buds Per Vine LQ']
        existingBlock['Buds Per Vine Mean'] += normalAndYoungVines * stats['buds Per Vine Mean']
        existingBlock['Buds Per Vine UQ'] += normalAndYoungVines * stats['buds Per Vine UQ']
        existingBlock['Buds Per Spur Per Vine LQ'] += normalAndYoungVines * stats['buds Per Spur Per Vine LQ']
        existingBlock['Buds Per Spur Per Vine Mean'] += normalAndYoungVines * stats['buds Per Spur Per Vine Mean']
        existingBlock['Buds Per Spur Per Vine UQ'] += normalAndYoungVines * stats['buds Per Spur Per Vine UQ']
        existingBlock['Spurs Per Vine LQ'] += normalAndYoungVines * stats['spurs Per Vine LQ']
        existingBlock['Spurs Per Vine Mean'] += normalAndYoungVines * stats['spurs Per Vine Mean']
        existingBlock['Spurs Per Vine UQ'] += normalAndYoungVines * stats['spurs Per Vine UQ']
        existingBlock['Buds Per Cane LQ'] += normalAndYoungVines * stats['buds Per Cane LQ']
        existingBlock['Buds Per Cane Mean'] += normalAndYoungVines * stats['buds Per Cane Mean']
        existingBlock['Buds Per Cane UQ'] += normalAndYoungVines * stats['buds Per Cane UQ']
        existingBlock['Missing Posts'] += stats['missing Posts'] || 0
        existingBlock['Metal Posts'] += stats['metal Posts'] || 0
        existingBlock['Wooden Posts'] += stats['wooden Posts'] || 0
        existingBlock['Unknown Posts'] += stats['unknown Posts'] || 0
        // if existingBlock['Assumed Cane Target'] is lower than the new value, update it
        existingBlock['Assumed Cane Target'] =
          existingBlock['Assumed Cane Target'] < stats['assumed Cane Target']
            ? stats['assumed Cane Target']
            : existingBlock['Assumed Cane Target']
      } else {
        const blockName = blocksAsMap.get(row.blockId)?.name || 'Not found'
        // If blockId doesn't exist, create a new entry
        const newEntry: PruningRowStatsAggregated = {
          'Block Id': row.blockId,
          'Block Name': blockName,
          'Block Code': blockName + '>' + row.blockId,
          'Start Date': row.scanDate,
          'End Date': row.scanDate,
          'Total Rows': 1,
          'Normal Vines': stats['normal Vines'],
          'Young Vines': stats['young Vines'],
          'Young Vines%': 0,
          'Dead Vines': stats['dead Vines'],
          'Dead Vines%': 0,
          'Missing Vines': stats['missing Vines'],
          'Missing Vines%': 0,
          'Unknown Vines': stats['unknown Vines'],
          'Bad Quality Vines': stats['bad Quality Vines'],
          'Low Quality Vines': stats['bad Quality Vines'],
          'Productive%': stats['productive%'],
          'Pruned To Target%': 0,
          'Not Pruned To Target%': 0,
          'Canes Laid%': 0,
          'Canes Not Laid%': 0,
          'Assumed Cane Target': stats['assumed Cane Target'],
          '1 Cane': stats['1 Cane'],
          '2 Cane': stats['2 Cane'],
          '3 Cane': stats['3 Cane'],
          '4 Cane': stats['4 Cane'],
          '1 Cane%': 0,
          '2 Cane%': 0,
          '3 Cane%': 0,
          '4 Cane%': 0,
          'Total Vines': 0,
          'Total Vines With Canes': 0,
          'Total Canes': 0,
          'Bow Buds Per Vine Mean': normalAndYoungVines * stats['buds Per Vine'],
          '1 Missing Cordon': stats['missing Cordons 1'],
          '1 Missing Cordon%': 0,
          '2 Missing Cordons': stats['missing Cordons 2'],
          '2 Missing Cordons%': 0,
          'Buds Per Vine LQ': normalAndYoungVines * stats['buds Per Vine LQ'],
          'Buds Per Vine Mean': normalAndYoungVines * stats['buds Per Vine Mean'],
          'Buds Per Vine UQ': normalAndYoungVines * stats['buds Per Vine UQ'],
          'Buds Per Spur Per Vine LQ': normalAndYoungVines * stats['buds Per Spur Per Vine LQ'],
          'Buds Per Spur Per Vine Mean': normalAndYoungVines * stats['buds Per Spur Per Vine Mean'],
          'Buds Per Spur Per Vine UQ': normalAndYoungVines * stats['buds Per Spur Per Vine UQ'],
          'Spurs Per Vine LQ': normalAndYoungVines * stats['spurs Per Vine LQ'],
          'Spurs Per Vine Mean': normalAndYoungVines * stats['spurs Per Vine Mean'],
          'Spurs Per Vine UQ': normalAndYoungVines * stats['spurs Per Vine UQ'],
          'Buds Per Cane LQ': normalAndYoungVines * stats['buds Per Cane LQ'],
          'Buds Per Cane Mean': normalAndYoungVines * stats['buds Per Cane Mean'],
          'Buds Per Cane UQ': normalAndYoungVines * stats['buds Per Cane UQ'],
          'Missing Posts': stats['missing Posts'] || 0,
          'Metal Posts': stats['metal Posts'] || 0,
          'Wooden Posts': stats['wooden Posts'] || 0,
          'Unknown Posts': stats['unknown Posts'] || 0,
        }

        result.push(newEntry)
      }

      return result
    }, [])
    .map((block) => {
      const deadVines = isNaN(block['Dead Vines']) ? 0 : block['Dead Vines']
      const totalVines = block['Normal Vines'] + block['Young Vines'] + block['Missing Vines'] + deadVines
      const totalVinesWithCanes = block['1 Cane'] + block['2 Cane'] + block['3 Cane'] + block['4 Cane']
      const blockCaneTarget = `${block['Assumed Cane Target']} Cane` as '1 Cane' | '2 Cane' | '3 Cane' | '4 Cane'
      const normalAndYoungVines = block['Normal Vines'] + block['Young Vines']

      const prunedToTargetPercent = Number(((block[blockCaneTarget] / totalVinesWithCanes) * 100).toFixed(1))

      const canePotential = Number(calculateBlockPotential(block))
      const totalCanes = block['1 Cane'] + block['2 Cane'] * 2 + block['3 Cane'] * 3 + block['4 Cane'] * 4

      const [fourCanePct, threeCanePct, twoCanePct, oneCanePct] = adjustPercentagesTo100(
        [block['4 Cane'], block['3 Cane'], block['2 Cane'], block['1 Cane']],
        (value) => Number(((value / totalVinesWithCanes) * 100).toFixed(1))
      )

      const [youngVinesPct, deadVinesPct, missingVinesPct] = adjustPercentagesTo100(
        [block['Young Vines'], block['Dead Vines'], block['Missing Vines'], block['Normal Vines']],
        (value) => Number(((value / totalVines) * 100).toFixed(1))
      )

      // Calculating based on the overall percentage of each row within that block
      block['Productive%'] = Number(((block[blockCaneTarget] / totalVines) * 100).toFixed(1))
      block['Pruned To Target%'] = prunedToTargetPercent
      block['Not Pruned To Target%'] = Number((100 - prunedToTargetPercent).toFixed(1))
      block['Canes Laid%'] = canePotential
      block['Canes Not Laid%'] = Number((100 - canePotential).toFixed(1))
      block['4 Cane%'] = fourCanePct || 0
      block['3 Cane%'] = threeCanePct || 0
      block['2 Cane%'] = twoCanePct || 0
      block['1 Cane%'] = oneCanePct || 0
      block['Young Vines%'] = youngVinesPct || 0
      block['Dead Vines%'] = deadVinesPct || 0
      block['Missing Vines%'] = missingVinesPct || 0
      block['1 Missing Cordon%'] = Number(((block['1 Missing Cordon'] / totalVines) * 100).toFixed(1))
      block['2 Missing Cordons%'] = Number(((block['2 Missing Cordons'] / totalVines) * 100).toFixed(1))
      block['Total Vines'] = totalVines
      block['Total Vines With Canes'] = totalVinesWithCanes
      block['Total Canes'] = totalCanes
      block['Bow Buds Per Vine Mean'] = Number((block['Bow Buds Per Vine Mean'] / normalAndYoungVines).toFixed(1))
      block['Buds Per Vine LQ'] = Number((block['Buds Per Vine LQ'] / normalAndYoungVines).toFixed(1))
      block['Buds Per Vine Mean'] = Number((block['Buds Per Vine Mean'] / normalAndYoungVines).toFixed(1))
      block['Buds Per Vine UQ'] = Number((block['Buds Per Vine UQ'] / normalAndYoungVines).toFixed(1))
      block['Buds Per Spur Per Vine LQ'] = Number((block['Buds Per Spur Per Vine LQ'] / normalAndYoungVines).toFixed(1))
      block['Buds Per Spur Per Vine Mean'] = Number((block['Buds Per Spur Per Vine Mean'] / normalAndYoungVines).toFixed(1))
      block['Buds Per Spur Per Vine UQ'] = Number((block['Buds Per Spur Per Vine UQ'] / normalAndYoungVines).toFixed(1))
      block['Spurs Per Vine LQ'] = Number((block['Spurs Per Vine LQ'] / normalAndYoungVines).toFixed(1))
      block['Spurs Per Vine Mean'] = Number((block['Spurs Per Vine Mean'] / normalAndYoungVines).toFixed(1))
      block['Spurs Per Vine UQ'] = Number((block['Spurs Per Vine UQ'] / normalAndYoungVines).toFixed(1))
      block['Buds Per Cane LQ'] = Number((block['Buds Per Cane LQ'] / normalAndYoungVines).toFixed(1))
      block['Buds Per Cane Mean'] = Number((block['Buds Per Cane Mean'] / normalAndYoungVines).toFixed(1))
      block['Buds Per Cane UQ'] = Number((block['Buds Per Cane UQ'] / normalAndYoungVines).toFixed(1))

      return block
    })

  // categorise blocks data by pruning style
  const blocksDataByPruningStyle = {
    cane: blocksPruningStats.filter((block) => canePrunedBlockIds.includes(block['Block Id'])),
    spur: blocksPruningStats.filter((block) => spurPrunedBlockIds.includes(block['Block Id'])),
    bow: blocksPruningStats.filter((block) => bowPrunedBlockIds.includes(block['Block Id'])),
  }

  return blocksDataByPruningStyle
}

export function findHighestValueBlockNames(
  arr: PruningRowStatsAggregated[],
  key: keyof PruningRowStatsAggregated
): { blockNames: string[]; value: number } {
  let maxValue = 0
  const blockNames: string[] = []

  arr.forEach((obj) => {
    const value = obj[key]
    if (typeof value === 'number' && value > maxValue) {
      // Found a higher value, update maxValue and reset blockIds
      maxValue = value
      blockNames.length = 0
      blockNames.push(obj['Block Name'])
    } else if (value === maxValue) {
      // Found an equal value, add blockId to blockIds
      blockNames.push(obj['Block Name'])
    }
  })

  return { blockNames, value: maxValue }
}

export function findLowestValueBlockNames(
  arr: PruningRowStatsAggregated[],
  key: keyof PruningRowStatsAggregated
): { blockNames: string[]; value: number } {
  let minValue = Infinity
  const blockNames: string[] = []

  arr.forEach((obj) => {
    const value = obj[key]
    if (typeof value === 'number' && value < minValue) {
      // Found a lower value, update minValue and reset blockIds
      minValue = value
      blockNames.length = 0
      blockNames.push(obj['Block Name'])
    } else if (value === minValue) {
      // Found an equal value, add blockId to blockIds
      blockNames.push(obj['Block Name'])
    }
  })

  return { blockNames, value: minValue }
}

export function rowStatsPruningToEChartsData(data: PruningRowStatsAggregated[], t: TFunction, calculateBy: 'vine' | 'cane') {
  let totalVinesWithTargetedCane = 0
  let totalVinesWithCanes = 0
  let numerator = 0
  let denominator = 0

  data.forEach((block) => {
    const blockKeyByCaneTarget = `${block['Assumed Cane Target']} Cane` as '1 Cane' | '2 Cane' | '3 Cane' | '4 Cane'
    totalVinesWithTargetedCane += block[blockKeyByCaneTarget]
    const totalVines = block['1 Cane'] + block['2 Cane'] + block['3 Cane'] + block['4 Cane']
    totalVinesWithCanes += totalVines

    // debugger

    const totalPotential =
      4 * (block['4 Cane'] || 0) + 3 * (block['3 Cane'] || 0) + 2 * (block['2 Cane'] || 0) + (block['1 Cane'] || 0)

    numerator += totalPotential
    denominator += (block['Assumed Cane Target'] || 1) * totalVines
  })

  if (calculateBy === 'vine') {
    const totalPrunedPercentage = Number(((totalVinesWithTargetedCane / totalVinesWithCanes) * 100).toFixed(1))

    return [
      { name: t('pruned_to_target'), value: totalPrunedPercentage, vines: totalVinesWithTargetedCane },
      {
        name: t('not_pruned_to_target'),
        value: Number((100 - totalPrunedPercentage).toFixed(1)),
        vines: totalVinesWithCanes - totalVinesWithTargetedCane,
      },
    ]
  }

  const totalBlockPotential = Number(((numerator / denominator) * 100).toFixed(1))

  return [
    { name: t('canes_laid'), value: totalBlockPotential, vines: numerator },
    { name: t('canes_not_laid'), value: Number((100 - totalBlockPotential).toFixed(1)), vines: denominator - numerator },
  ]
}

export function createCanesDataset(data: PruningRowStatsAggregated[], calculateBy: 'cane' | 'vine') {
  if (calculateBy === 'vine') {
    return {
      id: 'canes-dataset',
      dimensions: ['blockCode', 'itemA', 'itemB', 'itemC', 'itemD'],
      source: data.map((item) => {
        const assumedCaneTarget = item['Assumed Cane Target']
        let itemA = 0
        let itemB = 0
        let itemC = 0
        let itemD = 0

        if (assumedCaneTarget === 4) {
          itemA = item['4 Cane%']
          itemB = item['3 Cane%']
          itemC = item['2 Cane%']
          itemD = item['1 Cane%']
        } else if (assumedCaneTarget === 3) {
          itemA = item['3 Cane%']
          itemB = 0
          itemC = item['2 Cane%']
          itemD = item['1 Cane%']
        } else if (assumedCaneTarget === 2) {
          itemA = item['2 Cane%']
          itemB = 0
          itemC = 0
          itemD = item['1 Cane%']
        } else if (assumedCaneTarget === 1) {
          itemA = item['1 Cane%']
          itemB = 0
          itemC = 0
          itemD = 0
        }

        return {
          blockCode: item['Block Name'] + '>' + item['Block Id'] + assumedCaneTarget,
          assumedCaneTarget,
          itemA,
          itemB,
          itemC,
          itemD,
          '4 Cane%': item['4 Cane%'],
          '3 Cane%': item['3 Cane%'],
          '2 Cane%': item['2 Cane%'],
          '1 Cane%': item['1 Cane%'],
          '4 Cane': item['4 Cane'],
          '3 Cane': item['3 Cane'],
          '2 Cane': item['2 Cane'],
          '1 Cane': item['1 Cane'],
          totalVines: item['Total Vines With Canes'],
        }
      }),
    }
  }

  return {
    id: 'canes-dataset',
    dimensions: ['blockCode', 'canesLaid%', 'canesNotLaid%'],
    source: data.map((item) => {
      const assumedCaneTarget = item['Assumed Cane Target']
      const canesNotLaid = assumedCaneTarget * item['Total Vines With Canes'] - item['Total Canes']

      return {
        blockCode: item['Block Name'] + '>' + item['Block Id'] + assumedCaneTarget,
        assumedCaneTarget,
        itemA: item['Canes Laid%'],
        'canesLaid%': item['Canes Laid%'],
        'canesNotLaid%': item['Canes Not Laid%'],
        canesLaid: item['Total Canes'],
        canesNotLaid,
      }
    }),
  }
}

export function colorByAssumedCaneTarget(assumedCaneTarget: 1 | 2 | 3 | 4, key: string, range: ColorRange) {
  if (assumedCaneTarget === 4) {
    return key === '4_cane' ? range.posHigh : key === '3_cane' ? range.posLow : key === '2_cane' ? range.negLow : range.negHigh
  } else if (assumedCaneTarget === 3) {
    return key === '3_cane' ? range.posHigh : key === '2_cane' ? range.negLow : range.negHigh
  } else if (assumedCaneTarget === 2) {
    return key === '2_cane' ? range.posHigh : range.negHigh
  } else if (assumedCaneTarget === 1) {
    return key === '1_cane' ? range.posHigh : '#E7E5E4'
  }
}

export function colorByVineStatsKey(key: string, range: ColorRange) {
  return key === 'young_vines' ? range.posLow : key === 'dead_vines' ? range.neutral : range.negHigh
}

export function createLandVineStatsDataset(data: PruningRowStatsAggregated[]) {
  const dataset = {
    id: 'land-vine-stats-dataset',
    dimensions: ['Block Code', 'Missing Vines%', 'Dead Vines%', 'Young Vines%'],
    source: [...data],
  }

  return dataset
}

export function createPostsDataset(data: PruningRowStatsAggregated[]) {
  const dataset = {
    id: 'posts-stats-dataset',
    dimensions: ['Block Code', 'wooden', 'metal', 'missing', 'unknown'],
    source: data.map((item) => {
      return {
        'Block Code': item['Block Code'],
        wooden: item['Wooden Posts'] || 0,
        metal: item['Metal Posts'] || 0,
        missing: item['Missing Posts'] || 0,
        unknown: item['Unknown Posts'] || 0,
      }
    }),
  }

  return dataset
}

export const numberOfUniqueBlocks = (data: RowStatsPruningDto[]) => {
  const blocks = new Set<number>()
  data.forEach((row) => blocks.add(row.blockId))
  return blocks.size
}

export const numberOfUniqueBlocksInAggregatedData = (data: PruningRowStatsAggregated[]) => {
  const blocks = new Set<number>()
  data.forEach((row) => blocks.add(row['Block Id']))
  return blocks.size
}

//number separator en-NZ
export const numberFmtThousandSeparator = (value: number) => value.toLocaleString('en-NZ')

export const datagridColumnValueFormatter: GridValueFormatter = (value) => {
  if (value === undefined || value === null || isNaN(value)) return '—'
  return value
}

// Curried formatter function
export const createFormatter = (customFormatter?: (value: any) => any) => {
  return (value: never, row: GridValidRowModel, column: GridColDef<GridValidRowModel, any, any>, apiRef: any): any => {
    const formattedValue = datagridColumnValueFormatter(value, row, column, apiRef)
    if (formattedValue === '—') return formattedValue // Return early if value is '—'
    return customFormatter ? customFormatter(formattedValue) : formattedValue
  }
}

export function adjustPercentagesTo100(values: number[], modifier: (value: number) => number = (value) => value) {
  let percentages

  if (modifier) percentages = values.map(modifier)
  else percentages = values

  const total = percentages.reduce((acc, curr) => acc + curr, 0)
  // If the sum is already 100, the original values is returned.
  if (total === 100) return percentages

  const diff = 100 - total
  // Error if the difference between the total and 100 is greater than 0.9.
  if (Math.abs(diff) > 0.9)
    throw new Error('The difference between the total and 100 is greater than 0.9. Please check the data.')

  // Find the highest value in the array.
  const highestValue = Math.max(...percentages)

  // Find the number of decimal values in the highest value
  const numberOfDecimalValuesInMax = highestValue.toString().split('.')[1]?.length || 1

  // Find the index of the highest value in the array.
  const index = percentages.indexOf(highestValue)

  // Adjust the highest value by adding or subtracting the difference
  percentages[index] = Number((highestValue + diff).toFixed(numberOfDecimalValuesInMax))

  return percentages
}

export const getLandingPage = (country: CountryCode | undefined): string => {
  if (!country) return 'health'
  const today = new Date()
  const month = today.getMonth() + 1
  const hemisphere = countriesInNorthHemisphere.includes(country) ? 'north' : 'south'
  const season = seasonsByHemisphere[hemisphere].find((s) => month >= s.startMonth && month <= s.endMonth)
  if (!season) return 'health'
  return season.landingPage
}
